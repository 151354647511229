.newsBox {
    overflow: hidden;
    position: relative;
}

.newsImg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 310px;
    object-position: top;
    transition: .5s;
    cursor: pointer;
}

.newsImg:hover {
    height: 105%;
    width: 105%;
    transition: .5s;
    cursor: pointer;
}

.newsContent button {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    background: #FFF;
    border: none;
    color: #000;
    outline: none;
    width: 100%;
}

.newsContent button:hover {
    color:#6c757d;
}

.newsContent {
    position: relative;
}

.newsCategory button {
    padding: 8px 10px;
    background: #000;
    color: #FFF;
    font-size: 15px;
    border: none;
    text-align: left;
    width: 100%;
}

.newsCategory button:hover {
    background: #4a4a4a;
    color: #FFF;
}

iframe.galleryvid {
    width: 100%;
    height: 275px;
}

/*Início Modal*/

div#modalSlider {
    background: #FFF;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    height: 100vh;
}

#modalSlider button.closeModal {
    color: #000;
}

.boxDivider {
    width: 50%;
    float: left;
    height: 100%;
}

div#bgBlack {
    background: #000;
    padding: 80px 45px;
    overflow: auto;
}

.boxContentModal {
    padding: 35px 15px;
    height: 100vh;
}

.boxTags button {
    background: #000;
    border: none;
    font-size: 15px;
    color: #FFF;
    padding: 6px 10px;
    margin-right: 20px;
    outline: none;
}

.boxTags button:hover {
    background: #343a40;
}

p.titleNews {
    margin-bottom: 30px;
    font-weight: bold;
    margin-top: 30px;
    color: #000;
}

.boxContent {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 90%;
    margin-top: 20px;
    padding-right: 20px;
}

/* width */
.boxContent::-webkit-scrollbar {
    width: 13px;
  }
  
  /* Track */
.boxContent::-webkit-scrollbar-track {
    background: #e5e5e5; 
  }
   
  /* Handle */
.boxContent::-webkit-scrollbar-thumb {
    background: #000; 
  }

.boxContent p {
    margin-bottom: 40px;
}

.aboutContact img {
    width: 100%;
    max-width: 67px;
    border-radius: 100%;
    float: left;
}

.infoContact {
    padding-left: 80px;
}

.infoContact p {
    margin-bottom: -3px;
}

@media screen and (max-width: 768px) {
    div#modalSlider {
        overflow-y: scroll;
    }
    #modalSlider button.closeModal {
        color: #FFF;
    }
    .slick-slide.slick-active.slick-current {
       height: 550px;
    }
    .boxDivider {
        width: 100%;
        height: 87%;
    }
    .boxContent {
        overflow-y: initial;
        overflow-x: initial;
        padding-right: 0;
        padding-bottom: 15px;
    }
    .boxContentModal {
        height: auto;
    }
}

@media screen and (min-width: 1500px) {
    .newsBox, .newsCategory, .newsContent {
        width: 70%;
        margin: 0 auto;
    }
}
.slick-slide.slick-active.slick-current {
    height: 535px;
    position: relative;
}

.slider-home .slick-slide.slick-active.slick-current {
    height: 100vh;
    position: relative;
}

.boxSlider {
    padding: 0 5px;
}

button.btnDownloadImg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); 
    background: transparent;
    border: 2px solid #FFF;
    font-size: 16px;
    color: #FFF;
    font-weight: 700;
    padding: 7px 17px;
    text-transform: uppercase;
    outline: none;
}

button.btnDownloadImg:hover {
    background: #343a40;
}

.sliderRight .slick-slide img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    max-height: 370px;
}

.boxSlider .infoBox {
    margin: 5px 0;
}

.slick-dots {
    position: absolute;
    text-align: center;
    bottom: 55px;
}

.slick-dots li {
    margin: 0 4px;
    width: auto;
    height: auto;
}

.slick-dots li:first-child {
    margin: 0 4px 0 0;
}

.slick-dots li button {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: .25;
    background-color: #ccc;
}

.slick-dots li.slick-active button {
    opacity: 1;
    background-color: white;
}

.slick-dots li button:before {
    font-size: 0;
}

div#boxCredits {
    height: 87px;
    overflow: auto;
}

#boxCredits p {
    color: #FFF;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: -5px;
}

@media screen and (max-width: 768px) {
    .slick-slide.slick-active.slick-current {
        height: 800px;
        position: relative;
    }
    .slick-dots {
        position: absolute;
        text-align: center;
        bottom: 55px;
    }
    div#boxCredits {
        height: 105px;
        overflow: auto;
    }
}
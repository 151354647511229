@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

h1, h2, h3, h4, h5, h6, p, a, button, input, textarea, a, li {
    font-family: 'Montserrat', sans-serif;
}

input, button, textarea {
    outline: none;
}

.container {
    position: relative;
}

a {
    transition: .2s;
}

a:hover {
    text-decoration: none;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 2000px;
    }
}

@media(min-width: 576px){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 25px;
        padding-left: 25px;
    }
}

/*Início Modais*/

div#modal {
    width: 100%;
    height: 100vh;
    background: #282828;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#modal p {
    color: #aeaeae;
    font-weight: 500;
    font-size: 25px;
}

button.closeModal {
    background: transparent;
    border: none;
    font-size: 32px;
    position: absolute;
    top: 5px;
    right: 15px;
    color: #FFF;
    z-index: 999;
}

.modalLogin {
    text-align: center;
}

.formLogin input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #6c757d;
    border-radius: 0;
    font-size: 20px;
    margin-top: 30px;
}

.formLogin input:focus {
    background: transparent;
    box-shadow: none;
    border-bottom: 2px solid #FFF;
    color: #FFF;
}

.btnSend {
    padding: 10px;
    margin-top: 30px;
    border: 2px solid #FFF;
    background: transparent;
    color: #FFF;
    font-size: 19px;
    font-weight: 500;
}

.btnSend:hover {
    background: #343a40;
}

.btnSend.Profile {
    padding: 40px;
    border: 3px solid #000;
    width: 100%;
    text-transform: uppercase;
}

.boxFormLogin {
    background-color: #aeaeae;
    padding: 40px;
    max-width: 500px;
    margin: 0 auto;
}

.boxFormLogin p {
    color: #000 !important;
    font-size: 17px !important;
    font-weight: 600 !important;
}

.boxFormLogin label {
    display: table;
    margin: 0;
}

.boxFormLogin .form-control {
    border: none;
    border-bottom: 2px solid #000;
    background: transparent;
    border-radius: 0;
    height: 25px;
    padding: 0;
    color: #000;
    font-weight: 500;
}

.boxFormLogin .form-control:focus {
    box-shadow: none;
}

.btnSend.Black {
    background: #000;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
}

.btnSend.Black:hover {
    background: #282828;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 99;
}

div#modalFilters {
    position: fixed;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    width: 90%;
    max-width: 1500px;
}

#headerModal {
    background: #000;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#headerModal h3 {
    color: #aeaeae;
    margin: 0;
}

#headerModal .closeModal {
    position: initial;
    margin-top: -5px;
}

#modalBody {
    background: #282828;
    padding-top: 10px;
    padding-bottom: 30px;
    overflow-y: scroll;
    height: 357px;
}

/* width */
#modalBody::-webkit-scrollbar {
    width: 13px;
  }
  
  /* Track */
#modalBody::-webkit-scrollbar-track {
    background: #464646; 
  }
   
  /* Handle */
#modalBody::-webkit-scrollbar-thumb {
    background: #000; 
  }

.btnBrand {
    padding: 17px;
    margin-top: 20px;
    border: 2px solid #000;
    background: transparent;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
}

.btnBrand:hover {
    background: #6c757d;
}

.btnFilter {
    padding: 17px 20px;
    background-color: #aeaeae;
    border: none;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin: 30px auto 0;
    display: table;
}
.divFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
}

.slider-home .slick-slide {
    position: relative;
}

.slider-home .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.sliderHomeContent {
    height: 100vh;
}

.box-slider-fixed {
    position: absolute;
    bottom: 20%;
    right: 10px;
    background: #ed1c24;
}

.box-slider-fixed-bg {
    position: relative;
    top: -10px;
    right: -10px;
    background-color: #282828;
    display: flex;
    padding: 15px 70px 15px 70px;
    flex-direction: column;
}

.box-slider-fixed-bg > span {
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.box-slider-fixed-bg h1 {
    font-size: 5vw;
    color: #FFF;
    margin: 0;
}

.box-slider-fixed-bg h1 span {
    color: #ed1c24;
}

.box-slider-fixed-bg p {
    font-size: 24px;
    color: #ed1c24;
    text-align: right;
    font-weight: 400;
}

p.mediaRoom {
    text-align: center;
    color: #000;
    background: #FFF;
    padding: 4px 10px;
    font-size: 27px;
    width: 200px;
}

.resultGray {
    background-color: #282828;
    padding: 20px 0;
    margin-top: 100vh;
}

.resultFilter h4 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 90px;
}

.marginTopBigger {
    margin-top: 195px;
}

.marginTopSmaller {
    margin-top: 130px;
}

@media screen and (max-width: 768px) {
    #sliderFull img {
        width: auto;
        opacity: .6;
        margin-top: 0;
        min-height: 100%;
        object-fit: cover;
    }
    .boxHome {
        position: absolute;
        top: 175px;
    }
    .boxHome h1 {
        font-size: 33px;
        text-align: center;
    }
    .boxHome p {
        font-size: 16px;
        text-align: center;
        margin-top: -5px;
    }
    p.mediaRoom {
        padding: 4px;
        font-size: 19px !important;
        width: 145px;
        margin: -10px auto 0;
    }
    .sectionSlider {
        min-height: auto;
    }
}

@media screen and (max-width: 765px) {
    .resultFilter h4 {
        margin-bottom: 0;
    }
    .marginTopSmaller {
        margin-top: 65px;
        margin-bottom: 65px;
    }
    .marginTopBigger {
        margin-top: 65px;
    }
}
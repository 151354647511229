header {
    padding: 15px 0;
    transition: all 0.5s linear;
    position: fixed;
    min-height: 120px;
    background: #000;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
}

header.fixed {
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000;
    z-index: 9;
    box-shadow: 0px 7px 20px 0px rgb(0 0 0 / 19%);
}

@media(max-width: 768px){
    header.fixed #searchBox {
        display: none;
    }
}    

#logo {
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
}

.nav-link {
    padding: 0;
}

#toggleMenu {
    background: transparent;
    border: none;
    outline: none;
    display: none;
    position: absolute;
    right: 15px;
    top: 20px;
}

.rowToggle {
    width: 35px;
    height: 3px;
    margin: 10px 0;
    background: #FFF;
    border-radius: 2px;
}

#mainMenu {
    padding: 0;
    margin: 7px 0 0 0;
    display: inline-flex;
    list-style: none;
}

#mainMenu li:not(:last-child) {
    margin-right: 10px;
}

.dropdown-toggle::after {
    display: none;
}

#mainMenu li a {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFF;
    border-bottom: 3px solid transparent;
}

#mainMenu li a:hover {
    border-bottom: 3px solid #FFF;
}

header.fixed #mainMenu li a {
    color: #FFF;
}

button.login {
    background: #FFF;
    color: #000;
    font-size: 14px;
    border: 1px solid #FFF;
    font-weight: 500;
    padding: 10px;
    margin-top: -20px;
    margin-left: 75px;
}

.dropdown-menu {
    min-width: 0;
    background-color: transparent;
    border: none;
}

a.dropdown-item {
    background: #000;
    font-weight: 400 !important;
    margin-bottom: 10px;
    padding: 0;
    border-bottom: none !important;
    text-transform: initial !important;
    padding: 6px;
    font-size: 14px !important;
}

a.dropdown-item:hover {
    background: #FFF;
    color: #000 !important;
}

header.fixed a.dropdown-item {
    color: #FFF !important;
}

header.fixed a.dropdown-item:hover {
    background: #000;
    color: #FFF !important;
}

#headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width: 768px){    
    header.fixed #mainMenu {
        top: 80px;
    }

    header.fixed #mainMenu li a {
        color: #FFF;
    }

    #mainMenu {
        flex-direction: column;
        height: 100vh;
        justify-content: center;
    }

    #mainMenu.show {
        display: block !important;
        margin-top: 120px;
    }
    
    #mainMenu.hide {
        display: none !important;
    }

    #mainMenu li a {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #mainMenu li:not(:last-child) {
        margin-right: 0;
    }

    button.login {
        margin: 9px auto 0;
        display: table;
    }

    #headerBox {
        justify-content: center;
        flex-direction: column;
    }

    #toggleMenu {
        display: block;
    }

    .dropdown-menu.show {
        background: #FFF;
        width: 100%;
        padding: 10px 10px 4px 10px;
    }
}
.newsBoxClipagem p.hour {
    font-size: 14px;
    color: #FFF;
}

.newsBoxClipagem button {
    background: #000;
    border: none;
    border-left: 7px solid #ed1c24;
    color: #FFF;
    padding: 10px 18px;
    font-size: 17px;
    outline: none;
    margin-bottom: 40px;
}

.newsBoxClipagem button:hover {
    color: #aeaeae;
}